import React, { Component } from "react";
import { HashRouter, Route, Redirect } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Login"));
const Register = React.lazy(() => import("./views/Template/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Template/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Template/Pages/Page500"));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <PrivateRoute path="/" name="Home" component={DefaultLayout} {...this.props} />
          <Route
            exact
            path="/login"
            name="Login Page"
            render={props => <Login {...props} />}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            render={props => <Register {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={props => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={props => <Page500 {...props} />}
          />
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;

function isAuthenticated() {
  return localStorage.getItem("user") != null;
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);
